<template>
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <info-toast :infoMessage="infoMessage" @resetInfoMessage="infoMessage = null">
        <template v-slot:info-message>
            <span class="pl-2 text-md">{{ infoMessage }}</span>
        </template>
    </info-toast>
    <BackArrow
        :pageTitles="{
            heading: 'Create New Request For Quote',
            content: 'BACK TO Request for quote',
        }"
    />
    <main class="bg-white mx-32 my-8 py-14 rounded-lg shadow">
        <h1 class="text-lg font-bold px-14">New Request for Quote</h1>
        <hr class="my-6" />
        <div class="flex mb-14 px-14 w-3/4">
            <div class="w-96 mr-12 capitalize">
                <label class="block leading-loose">project name</label>
                <input
                    v-model.lazy="projectName"
                    type="text"
                    class="border border-borderColor rounded p-2 w-full"
                />
            </div>
            <div @click="selectDate" class="w-96 relative capitalize">
                <label class="block leading-loose">project deadline</label>
                <div class="border border-borderColor rounded p-2 w-full" style="height: 42px">
                    {{ datePicker ? moment(datePicker).format('D MMM, YYYY') : null }}
                </div>
                <datePicker v-model.lazy="datePicker" class="hidden" />
                <img
                    src="@/assets/images/calendar-icon.svg"
                    alt="select date"
                    class="absolute right-4 bottom-3"
                />
            </div>
        </div>
        <h1 class="text-lg font-bold px-14 capitalize">Expenditure Request</h1>
        <hr class="my-6" />
        <div class="flex relative capitalize w-3/4 px-14">
            <div class="relative w-1/2">
                <div class="capitalize">expenditure request</div>
                <div
                    @click="showingExpenditureRequests = !showingExpenditureRequests"
                    class="flex border border-borderColor rounded mt-2  w-full h-10 p-2 shadow-sm"
                >
                    <input
                        v-if="selectedExpenditureRequest"
                        v-model="selectedExpenditureRequest.product_name"
                        type="text"
                        class="focus:outline-none w-full"
                        readonly
                    />
                </div>
                <img
                    @click="showingExpenditureRequests = !showingExpenditureRequests"
                    src="@/assets/images/chevron-down-icon-grey.svg"
                    alt="arrow down icon"
                    data-year="year"
                    class="absolute right-2 bottom-4 cursor-pointer"
                />
                <ul
                    v-if="showingExpenditureRequests"
                    class="absolute top-full w-full h-32 overflow-y-scroll overscroll-contain shadow bg-interactionBg"
                >
                    <li v-if="!expenditureRequests">loading...</li>
                    <li
                        @click="selectExpenditureRequest(expenditureRequest)"
                        v-for="expenditureRequest in expenditureRequests"
                        :key="expenditureRequest.id"
                        :data-expenditurerequest="expenditureRequest"
                        class="hover:bg-borderColor py-1 px-3"
                    >
                        {{ expenditureRequest.product_name }}
                    </li>
                </ul>
            </div>
        </div>
        <button
            @click="addToPreview"
            class="bg-primaryColor text-white rounded-full py-2 px-8 my-12 mx-14 font-semibold shadow-md focus:outline-none"
        >
            Add to list
        </button>
        <section v-if="previewData.length" class="px-14 text-sm capitalize mt-4">
            <table class="w-full table-fixed border border-borderColor">
                <thead class="font-semibold text-left capitalize">
                    <tr class="rounded-md">
                        <th class="w-1/2 border border-borderColor p-3">expenditure request</th>
                        <th class="w-1/2 border border-borderColor p-3">Decription</th>
                        <th class="w-1/3 border border-borderColor p-3">Project Deadline</th>
                        <th class="w-1/4 border border-borderColor p-3">Quantity</th>
                        <th class="w-1/4 border border-borderColor p-3">Unit Price</th>
                        <th class="w-1/4 border border-borderColor p-3">Taxes</th>
                        <th class="w-1/4 border border-borderColor p-3">Net price</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(preview, index) in previewData" :key="index">
                        <td class="border border-borderColor p-3 capitalize">
                            {{ preview.expenditureRequest }}
                        </td>
                        <td class="border border-borderColor p-3 capitalize">
                            {{ preview.description }}
                        </td>
                        <td class="border border-borderColor p-3 capitalize">
                            {{ preview.project_deadline }}
                        </td>
                        <td class="border border-borderColor p-3 capitalize">
                            {{ preview.quantity }}
                        </td>
                        <td class="border border-borderColor p-3 capitalize">
                            {{ preview.unitPrice }}
                        </td>
                        <td class="border border-borderColor p-3 capitalize">
                            {{ preview.taxes }}
                        </td>
                        <td class="relative border border-borderColor p-3 capitalize">
                            {{ $filters.currencyUSD(preview.netPrice) }}.00
                            <img
                                @click="removeFromPreview(preview)"
                                src="@/assets/images/cancel-icon.svg"
                                class="absolute -right-6 top-3 cursor-pointer"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
        <section v-if="previewData.length" class="flex flex-row-reverse my-10 mx-14">
            <div>
                <div class="text-xl">
                    <span class="uppercase">total:</span>
                    <span
                        class="font-bold ml-2 bg-interactionBg rounded-md p-3 shadow-sm tracking-wider"
                        >N {{ $filters.currencyUSD(total.toFixed(2)) }}.00</span
                    >
                </div>
            </div>
        </section>
        <h1 class="text-lg leading-9 font-bold mt-20 mx-14">Vendors</h1>
        <hr class="my-6" />
        <div v-if="vendors" class="w-96 mx-14 relative">
            <input
                v-model="vendorSearchTerm"
                type="text"
                placeholder="Search for vendors..."
                class="block w-full border border-borderColor rounded focus:outline-none p-2"
            />
            <div class="flex justify-between items-center mt-10 capitalize">
                <span>all vendors</span>
                <span
                    @click="addedAllvendors"
                    class="w-16 text-white bg-primaryColor rounded p-1 text-xs text-center cursor-pointer"
                    >add</span
                >
            </div>
            <div v-if="selectedVendors.length" class="mt-12">
                <div
                    v-for="vendor in selectedVendors"
                    :key="vendor.id"
                    class="flex justify-between items-center capitalize my-4"
                >
                    <span>{{ vendor.vendor_details.name }}</span>
                    <span
                        @click="removeVendor(vendor)"
                        class="w-16 text-white bg-pillRejectedStatus rounded p-1 text-xs text-center cursor-pointer"
                        >remove</span
                    >
                </div>
            </div>
            <ul
                v-if="showFilteredResult"
                class="absolute top-10 bg-white border border-borderColor rounded w-full max-h-48 overflow-y-scroll"
            >
                <li
                    @click="selectVendor(vendor)"
                    v-for="vendor in filtereResult"
                    :key="vendor.vendor_details.id"
                    class="px-2 py-3 hover:bg-lightGreyBg cursor-pointer"
                >
                    {{ vendor.vendor_details.name }}
                </li>
            </ul>
        </div>
        <SubmitButton
            @submitRequest="submitRequest"
            buttonText="Submit Request"
            class="block ml-auto mr-14"
            :class="{ 'cursor-not-allowed': submitting }"
        />
    </main>
</template>

<script>
import Request from '@/services/requestHelper'
import BackArrow from '@/components/ui/BackArrow.vue'
import SubmitButton from '@/components/ui/SubmitButton.vue'
import moment from 'moment'
import errorFunc from '@/util/error'

export default {
    name: 'QuotesView',
    components: { BackArrow, SubmitButton },
    data() {
        return {
            projectName: null,
            expenditureRequestsId: [],
            vendorId: [],
            selectedExpenditureRequest: null,
            showingExpenditureRequests: false,
            vendorSearchTerm: null,
            moment,
            datePicker: null,
            total: 0,
            expenditureRequests: null,
            previewData: [],
            vendors: [],
            selectedVendors: [],
            selectedVendorsId: [],
            errorMessage: null,
            infoMessage: null,
            filtereResult: null,
            showFilteredResult: false,
            submitting: false,
        }
    },
    watch: {
        showingExpenditureRequests() {
            if (this.showingExpenditureRequests) {
                this.getAllExpenditureRequests()
            }
        },
        vendorSearchTerm() {
            if (this.vendorSearchTerm) {
                const matchedVendors = this.vendors.filter(data =>
                    data.vendor_details.name
                        .toLowerCase()
                        .includes(this.vendorSearchTerm.toLowerCase())
                )
                this.filtereResult = matchedVendors
                this.showFilteredResult = true
            }
        },
    },
    mounted() {
        this.getAllVendors()
    },
    methods: {
        selectDate() {
            const element = document.querySelector('.v3dp__datepicker').firstChild
            element.click()
        },
        selectExpenditureRequest(value) {
            this.selectedExpenditureRequest = value
            this.showingExpenditureRequests = false
        },
        addToPreview() {
            const netPrice =
                Number(this.selectedExpenditureRequest.unit_cost) *
                Number(this.selectedExpenditureRequest.quantity)

            const previewData = {
                expenditureRequest: this.selectedExpenditureRequest.product_name,
                description: this.selectedExpenditureRequest.product_description,
                project_deadline: this.datePicker,
                quantity: this.selectedExpenditureRequest.quantity,
                unitPrice: this.selectedExpenditureRequest.unit_cost,
                expenditure_requests_id: this.selectedExpenditureRequest.id,
                taxes: 0,
                netPrice,
            }

            this.previewData.push(previewData)
            this.expenditureRequestsId.push(this.selectedExpenditureRequest.id)
            this.total += netPrice
            this.selectedExpenditureRequest = null
        },
        removeFromPreview(value) {
            const index = this.previewData.indexOf(value)
            const removedData = this.previewData.splice(index, 1)
            const expenditureRequestIdOfRemovedData = removedData[0].expenditure_requests_id
            this.expenditureRequestsId.splice(
                this.expenditureRequestsId.indexOf(expenditureRequestIdOfRemovedData),
                1
            )
            this.total -= removedData[0].netPrice
        },
        async getAllExpenditureRequests() {
            try {
                const { status, data } = await Request.getRequest(
                    'expenditure/requests/approved/by/management',
                    process.env.VUE_APP_FINANCE_BASE_URL
                )

                if (status === 200) {
                    this.expenditureRequests = data.data.data
                }
            } catch (error) {
                this.errorMessage = error.response.data.message
            }
        },
        async getAllVendors() {
            try {
                const { data } = await Request.getRequest('vendor')

                this.vendors = data.data.data

                this.vendors.forEach(vendor => {
                    this.selectedVendorsId.push(vendor.vendor_details.id)
                })
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
        removeVendor(vendor) {
            const vendorIndex = this.selectedVendors.indexOf(vendor)
            const vendorIdIndex = this.selectedVendorsId.indexOf(vendor.vendor_details.id)

            this.selectedVendors.splice(vendorIndex, 1)
            this.selectedVendorsId.splice(vendorIdIndex, 1)
        },
        selectVendor(vendor) {
            const vendorAvalable = this.checkAvailability(
                this.selectedVendors,
                vendor.vendor_details.id
            )

            if (!vendorAvalable) {
                this.selectedVendors.push(vendor)
                this.showFilteredResult = false
                this.vendorSearchTerm = null
            } else {
                this.infoMessage = 'This vendor is already on the  list.'
                this.showFilteredResult = false
                this.vendorSearchTerm = null
            }
        },
        addedAllvendors() {
            this.selectedVendors = this.vendors
        },
        checkAvailability(arr, val) {
            return arr.some(arrVal => val === arrVal.id)
        },
        async submitRequest() {
            try {
                if (!this.projectName) {
                    this.errorMessage = 'Project name is required.'
                    return
                }

                if (!this.datePicker) {
                    this.errorMessage = 'Project deadline is required.'
                    return
                }

                if (!this.expenditureRequestsId.length) {
                    this.errorMessage = 'Select at least one expenditure request.'
                    return
                }

                if (!this.selectedVendorsId) {
                    this.errorMessage = 'Select at least one vendor.'
                    return
                }

                if (this.submitting) {
                    return
                }

                this.submitting = true

                const payload = {
                    project_name: this.projectName,
                    project_deadline: this.datePicker,
                    expenditure_request_id: this.expenditureRequestsId,
                    vendor_id: this.selectedVendorsId,
                }

                const { status } = await Request.postRequest('request/quote/store', payload)

                if (status === 201) {
                    this.submitting = false
                    this.projectName = null
                    this.datePicker = null
                    this.previewData = []
                    this.expenditureRequestsId = []
                    this.vendorId = []
                    this.expenditureRequests = null
                    this.router.push({ name: 'quotes' })
                }
            } catch (error) {
                this.submitting = false
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
    },
}
</script>
